import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit } from "@angular/core";
import { BpeService, ToDoTemplate } from "../../../bpe/bpe.service";
import { EventService } from "../../../../services/event.service";
import { S25LoadingApi } from "../../../s25-loading/loading.api";
import { Bind } from "../../../../decorators/bind.decorator";
import { BpeUtil } from "../../../bpe/bpe.util";
import { S25Util } from "../../../../util/s25-util";
import { TypeManagerDecorator } from "../../../../main/type.map.service";
import { ExecutionEnvironment } from "../../../../services/execution.enviroment";

@TypeManagerDecorator("s25-ng-todo-email-template-preview")
@Component({
    selector: "s25-ng-todo-email-template-preview",
    template: `
        <s25-loading-inline [model]="{}"></s25-loading-inline>
        @if (status === "failed") {
            <div>
                <p>Failed to load event, please make sure your event reference is correct</p>
            </div>
        }
        @if (status === "success") {
            <div>
                <div>
                    <div>Assigned By: {{ finalAssignBy }}</div>
                    <div>Assigned To: {{ finalAssignTo }}</div>
                    <div>
                        Due Date:
                        <span class="ngInlineBlock">
                            <s25-ng-editable-date [val]="finalDueDate" [readOnly]="true"></s25-ng-editable-date>
                        </span>
                    </div>
                    <div>Comment:</div>
                    <div [innerHtml]="preview.comment | safeHTML"></div>
                </div>
            </div>
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25ToDoEmailTemplatePreviewComponent implements OnInit {
    @Input() name: ToDoTemplate["itemName"];
    @Input() scenarioName?: string;
    @Input() code: ToDoTemplate["code"];
    @Input() reference: string;
    @Input() isToDoTemplate: ToDoTemplate["isToDoTemplate"];

    status: "loading" | "failed" | "success" = "loading";
    preview: ToDoTemplate;
    finalDueDate: any;
    finalAssignTo: any;
    finalAssignBy: any;

    constructor(
        private elementRef: ElementRef,
        private changeDetector: ChangeDetectorRef,
    ) {}

    async ngOnInit() {
        this.isLoading(true);
        const id = await EventService.getEventIdByLocator(this.reference).catch(this.failed);
        if (!id) return this.failed();
        const event = await BpeService.getEventData(id, true);
        const vars = await BpeUtil.getVariables(event, null, event);
        vars.scenario = this.scenarioName;
        vars.template = this.name;
        const code = BpeUtil.interpolateString(vars, this.code);
        this.isLoading(false);

        try {
            this.preview = ExecutionEnvironment.run(code, {
                vars,
                eventData: event,
                util: BpeUtil.util,
            });

            let firstDate: any = new Date(event.start_date);
            let dueDate: any = this.preview.dueDate;
            this.finalDueDate = firstDate.setDate(firstDate.getDate() + parseInt(dueDate));

            if (!this.preview.assignBy.match(/[a-z]/i) && Number.isInteger(parseInt(this.preview.assignBy))) {
                let find: any;
                if (event.role) {
                    find = S25Util.array.forceArray(event.role).find((e: any) => {
                        return e && e.contact.contact_id === parseInt(this.preview.assignBy);
                    });
                    this.finalAssignBy = find.contact.contact_name;
                }
            } else {
                this.finalAssignBy = this.preview.assignBy;
            }

            if (!this.preview.assignTo.match(/[a-z]/i) && Number.isInteger(parseInt(this.preview.assignTo))) {
                let find: any;
                if (event.role) {
                    find = S25Util.array.forceArray(event.role).find((e: any) => {
                        return e && e.contact.contact_id === parseInt(this.preview.assignTo);
                    });

                    this.finalAssignTo = find.contact.contact_name;
                }
            } else {
                this.finalAssignTo = this.preview.assignTo;
            }

            // this.finalDueDate = S25Datefilter.transform(firstDate.setDate(firstDate.getDate() + parseInt(dueDate)), "yyyy-MM-dd" );
            this.status = "success";
        } catch (error: any) {
            S25Util.showError(error);
            this.failed();
        }
        this.changeDetector.detectChanges();
    }

    isLoading(loading: boolean) {
        if (loading) S25LoadingApi.init(this.elementRef.nativeElement);
        else S25LoadingApi.destroy(this.elementRef.nativeElement);
    }

    @Bind
    failed() {
        this.status = "failed";
        this.isLoading(false);
        this.changeDetector.detectChanges();
    }
}
